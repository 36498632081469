<script>
  import { updateSession, getRegion } from "../../utils.js";
  import { params, redirect } from "@roxi/routify";

  export let scoped;

  const { product, variant = "full" } = $params;

  if (product) {
    const { countries, prices, session, countryCode } = scoped;

    const region = getRegion(countries, countryCode);
    const regionalPrices = prices.filter(
      (price) => price.region.code === region
    );
    const productPrices = regionalPrices.filter(
      (price) => price.variant.product.code === product
    );
    const [price] = productPrices.filter(
      (price) => price.variant.code === variant
    );
    if (price)
      updateSession(session, {
        selectedPriceID: price.id,
        selectedPriceRegionCode: price.region.code,
      });
  }

  $redirect("./package");
</script>
