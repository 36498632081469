<script>
  import { clearSession } from "../../utils.js";
  import { goto, redirect } from "@roxi/routify";

  import OrderSummary from "../../components/OrderSummary.svelte";
  import PaymentForm from "../../components/PaymentForm.svelte";

  export let scoped;
  const { countries, price, session, countryCode, accessibleStages } = scoped;
  const accessible = accessibleStages.includes("payment");
  if (!accessible) $redirect("./details");

  function clearOut(order) {
    try {
      clearSession($session);
    } finally {
      const search = new URLSearchParams(
        [["orderID", order.id]].filter(Boolean)
      );

      $goto(`/success/ovateonlineoffer?${search}`);
    }
  }
</script>

{#if accessible}
  <OrderSummary {countries} {price} {countryCode} isOnline={true} />
  <PaymentForm
    {price}
    customerDetails={$session.customerDetails}
    {countryCode}
    isConsentGiven={$session.isConsentGiven}
    on:order={({ detail: order }) => clearOut(order)}
    paypal={false}
  />
{/if}
