<script>
  export let scoped;
  import CountrySelect from "../../components/CountrySelect.svelte";
  import { updateSession, getRegion, getCountryCode } from "../../utils.js";

  const { countries, detectedCountryCode, session } = scoped;

  const priceRegion = $session.selectedPriceRegionCode;
  let countryCode = getCountryCode({
    detectedCountryCode,
    selectedCountryCode: $session.selectedCountryCode,
  });

  function goBack() {
    window.history.back();
  }

  function formSubmit() {
    const data = { selectedCountryCode: countryCode };
    // If the customer previously selected a price from another region, unset
    // this.
    if (priceRegion && getRegion(countries, countryCode) !== priceRegion) {
      data.selectedPriceID = null;
      data.selectedPriceRegionCode = null;
    }
    updateSession(session, data);
    goBack();
  }
</script>

<CountrySelect
  {countries}
  bind:countryCode
  on:submit={formSubmit}
  on:goBack={goBack}
/>
